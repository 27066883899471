import { useEffect, useContext } from "react";
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { GlobalContext } from "../../../GlobalService";

import "../cusAcknowlegment/AckForm/AckForm.scss";
import {dashFormattedDate} from "./DateFormatter";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
});

const getProgramValue = (progVal) => {
    if (progVal == "") {
        return 0;
    } else if (typeof progVal === "string" && progVal.indexOf("-") == progVal.length - 1) {
        return 0 - parseFloat(progVal.substring(0, progVal.indexOf("-")));
    } else {
        return parseFloat(progVal);
    }
}

const CustomerIncentiveProg = ({
    setCustomerIncentiveProgram,
    discountInformation,
    discountProgram,
    incentiveData,
    acknowledgementData,
    customerIncentives,
    hasAuthCode,
    priceLevel}) => {
    const { acknowledgmentFormData, setAcknowledgmentFormData, supplierMap } = useContext(GlobalContext);

    let tempIncentive = [];
    let requestList = [];
    const specialHandlingTemp = ["TX07", "TX21", "TX27", "TX36"];     

    function checkDocuments(incentive) {
        const documentInfoSet = acknowledgementData?.documentInfoSet;
        const hasDocs = Boolean(documentInfoSet?.find((info) => info?.incentives.includes(incentive.progNum) && info?.documentType !== "CIA Form"));
        incentive.hasDocument = hasDocs;
        
        if (hasDocs === true && incentive.documentsUploaded === "Y") {
            return ["Complete"];
        } else if (hasDocs === true || incentive.documentsUploaded === "N") {
            return ["Incomplete"];
        } else if (hasDocs === false) {
            return ["N/A"];
        }
    }

    function displayQualifierName(incentive) {
        let qualifierName = [];
        if (incentive.qualifierDataResponses?.length > 0) {
            incentive.qualifierDataResponses.map((qualifier) => {
                if (qualifier.qualifierLabelNm !== "Approval Code")
                    qualifierName.push(qualifier.qualifierLabelNm);
            })
            return qualifierName
        } else {
            incentive.qualifierName = "N/A";
            return ["N/A"];
        }
    }

    function displayQualifierDesc(incentive) {
        let qualifierDesc = [];
        if (incentive.qualifierDataResponses?.length > 0) {
            incentive.qualifierDataResponses.map((qualifier) => {
                if (qualifier.qualifierLabelNm !== "Approval Code")
                    qualifierDesc.push(qualifier.qualifierVal);
            })
            return qualifierDesc;
        } else {
            incentive.qualifierDesc = "N/A";
            return ["N/A"];
        }
    }

       /**
     * Gets all the information from the table and
     * puts it in a json to send to backend
     */
       useEffect(() => {
        acknowledgementData.acknowledgementIncentiveData.map((x, i, arr) => {
            if (arr.length - 1 === i) {
            } else {

                let incentiveBundle = {};
                let discountElement = {};
                if (discountInformation && discountInformation?.discountArry?.find((ele) => ele.programNbr === x.progNum)) {
                    discountElement = discountInformation?.discountArry.find((ele) => ele.programNbr === x.progNum);
                }

                let certNum = "";
                let qualifierName = "";
                let authType = "";
                for (const qualName in x.qualifierDataResponses) {
                    if (x.qualifierDataResponses[qualName].qualifierLabelNm !== "Approval Code") {
                        qualifierName += x.qualifierDataResponses[qualName].qualifierLabelNm;
                        authType = x.qualifierDataResponses[qualName].authType;
                    }
                    if (x.qualifierDataResponses[qualName].qualifierLabelNm === "GM Card Certificate" || x.qualifierDataResponses[qualName].qualifierLabelNm === "Certificate Number") {
                        certNum = x.qualifierDataResponses[qualName].qualifierVal;
                    }
                }

                // Need to format the approval date for non LLOB incentives
                const isLLOBDiscount = authType === "GM-ACERT" || authType === "GM-VPP";
                const approvalDate = isLLOBDiscount
                    ? discountElement?.approvalDate
                    : dashFormattedDate(discountElement?.approvalDate);

                if (x.progNum === discountElement.programNbr) {
                    if (priceLevel === "SUP" || priceLevel === "EMP") {
                        x.incentiveType = "cda";
                    }
                    incentiveBundle = {
                        approvalDate,
                        approvalNumber: discountElement?.approvalNumber,
                        authCode: discountElement?.authorizationNumber,
                        authType: authType,
                        certNumber: certNum,
                        dateOfBirth: (discountInformation?.birthday ? dashFormattedDate(discountInformation?.birthday) : ""),
                        hasDocuments: x.hasDocuments ? x.hasDocuments : "N",
                        incentiveCode: x.incentiveCode,
                        incentiveType: x.incentiveType,
                        isDiscount: "Y",
                        postalCode: discountElement?.postalCode,
                        participant: discountElement?.participant,
                        progName: x.progName,
                        progNum: x.progNum,
                        progVal: x.progVal,
                        pricingForAll: "N",
                        progValueCurr: "USD",
                        qualifierName: qualifierName,
                        qualifier: discountElement?.authorizationNumber,
                        relationship: discountElement?.relationship,
                        relationshipSnapshotId: "",
                        relationshipType: discountElement?.relationship,
                        supplierName: supplierMap?.[x?.progNum],
                        transferred: x.transferred,
                    }

                    if((x.pricingLevelCode === "SUP" || x.pricingLevelCode === "EMP") && hasAuthCode && x.programType === "TEXT")
                    {
                        incentiveBundle.progVal = discountProgram.amountSix;
                    }
                } else if ((x.pricingLevelCode === "SUP" || x.pricingLevelCode === "EMP")&& x.dealType === "TOD") {
                    let qualifierName = "N/A";
                    let qualifier ="N/A";
                    let authType = "";
                    let certNum = "";
                    for (const qualName in x.qualifierDataResponses) {
                        if (x.qualifierDataResponses[qualName].qualifierLabelNm !== "Approval Code") {
                            qualifierName = x.qualifierDataResponses[qualName].qualifierLabelNm;
                            qualifier = x.qualifierDataResponses[qualName].qualifierVal;
                            authType = x.qualifierDataResponses[qualName].authType;
                        }
                        if (x.qualifierDataResponses[qualName].qualifierLabelNm === "GM Card Certificate" || x.qualifierDataResponses[qualName].qualifierLabelNm === "Certificate Number") {
                            certNum = x.qualifierDataResponses[qualName].qualifierVal;
                        }
                    }

                    incentiveBundle = {
                        approvalDate: "",
                        approvalNumber: "",
                        authCode: authType === "GM-CARD" ? qualifier : "",
                        authType: authType || "",
                        certNumber: certNum,
                        dateOfBirth: "",
                        hasDocuments: x.hasDocuments ? x.hasDocuments : "N",
                        incentiveCode: x.incentiveCode,
                        incentiveType: "formulaPricing",
                        isDiscount: "N",
                        postalCode: "",
                        progName: x.progName,
                        progNum: x.progNum,
                        progVal: x.progVal,
                        pricingForAll: "N",
                        participant: "",
                        progValueCurr: "USD",
                        qualifierName: qualifierName,
                        qualifier: qualifier,
                        relationship: "",
                        relationshipSnapshotId: "",
                        relationshipType: "",
                        supplierName: supplierMap?.[x?.progNum],
                        transferred: x.transferred,
                    };
                } else {
                    let qualifierName = "N/A";
                    let qualifier ="N/A";
                    let authType = "";
                    let certNum = "";
                    for (const qualName in x.qualifierDataResponses) {
                        if (x.qualifierDataResponses[qualName].qualifierLabelNm !== "Approval Code") {
                            qualifierName = x.qualifierDataResponses[qualName].qualifierLabelNm;
                            qualifier = x.qualifierDataResponses[qualName].qualifierVal;
                            authType = x.qualifierDataResponses[qualName].authType;
                        }
                        if (x.qualifierDataResponses[qualName].qualifierLabelNm === "GM Card Certificate" || x.qualifierDataResponses[qualName].qualifierLabelNm === "Certificate Number") {
                            certNum = x.qualifierDataResponses[qualName].qualifierVal;
                        }
                    }
    
                    incentiveBundle = {
                        approvalDate: "",
                        approvalNumber: "",
                        authCode: authType === "GM-CARD" ? qualifier : "",
                        authType: authType || "",
                        certNumber: certNum,
                        dateOfBirth: "",
                        hasDocuments: x.hasDocuments ? x.hasDocuments : "N",
                        incentiveCode: x.incentiveCode,
                        incentiveType: x.incentiveType,
                        isDiscount: "N",
                        postalCode: "",
                        progName: x.progName,
                        progNum: x.progNum,
                        progVal: x.progVal,
                        pricingForAll: "N",
                        participant: "",
                        progValueCurr: "USD",
                        qualifierName: qualifierName,
                        qualifier: qualifier,
                        relationship: "",
                        relationshipSnapshotId: "",
                        relationshipType: "",
                        supplierName: supplierMap?.[x?.progNum],
                        transferred: x.transferred,
                    };
                }
                tempIncentive.push(incentiveBundle);

                let customerIncentiveProgramAcknowledgementRequestList = {
                    programNbr: x.progNum,
                    transferredFlg: x.transferred === "Y" ? true : false,
                }
                requestList.push(customerIncentiveProgramAcknowledgementRequestList);


            }
        });
        
        setCustomerIncentiveProgram({
            isVisible: true,
            incentiveList: tempIncentive,
            totalAmount: incentiveData.at(-1)?.totalVal + parseFloat(discountProgram.amountSix),
            customerIncentiveProgramAcknowledgementRequestList: requestList,
        })

        setAcknowledgmentFormData({
            ...acknowledgmentFormData,
            customerIncentiveProgramAcknowledgementRequestList: requestList,
        })

        acknowledgmentFormData.customerIncentiveProgramAcknowledgementRequestList = requestList;

    }, [discountInformation, customerIncentives, incentiveData, discountProgram, supplierMap]);

    const totalValue = incentiveData.at(-1)?.totalVal + (discountProgram.amountSix ?  parseFloat(discountProgram.amountSix) : 0);

    return (
        <div className="group-wrapper">
            <div className="input-field-header">
                Customer Incentive Program Acknowledgement
            </div>
            <table
                id="customer-incentive"
                className="acknowledgment-table"
                bordercolor="0072CE"
            >
                <thead>
                <tr>
                    <th className="small-column">Incentive Code</th>
                    <th className="big-column">
                        Incentive Program Description
                    </th>
                    <th className="medium-column">Qualifier Name</th>
                    <th className="medium-column">Qualifier</th>
                    <th className="small-column">Documents</th>
                    <th className="small-column">Transferred</th>
                    <th className="small-column">Amount</th>
                </tr>
                </thead>
                <tbody>
                {incentiveData.map((incentive, i, arr) => {
                    if (arr.length - 1 !== i) {
                        let qualName = [""];
                        let qualDesc = [""];
                        qualName = displayQualifierName(incentive);
                        qualDesc = displayQualifierDesc(incentive);
                        return (
                            <tr>
                                <td>
                                    <div id={"incentive_code_" + incentive.incentiveCode} className="cust-ack-cell">
                                        {incentive.incentiveCode}
                                    </div>
                                </td>
                                <td>
                                    <div id={"incentive_program_desc_" + incentive.progName} className="cust-ack-cell">
                                        {incentive.progName}
                                    </div>
                                </td>
                                <td>
                                    {
                                        [...qualName].map((name) => (
                                            <div className="table-list">{!incentive.qualifierUploadedFlg ? "Authorization Number" : name}</div>
                                        ))
                                    }

                                </td>
                                <td>

                                    {
                                        [...qualDesc].map((desc) => (
                                            <div className="table-list">{!incentive.qualifierUploadedFlg ? "" : desc}</div>
                                        ))
                                    }

                                </td>
                                <td>
                                    {checkDocuments(incentive)}
                                </td>
                                <td>
                                    <TextField
                                        variant="standard"
                                        id={
                                            "transferred_" +
                                            incentive.incentiveCode
                                        }
                                        value={incentive?.transferred === "Y" ? "Yes" : "No"}
                                        size="small"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                    </TextField>
                                </td>
                                <td className="amount-cell">                                      
                                        {(incentive.pricingLevelCode === "EMP" || incentive.pricingLevelCode === "SUP")
                                         && incentive.programType === "TEXT" && hasAuthCode ?
                                            <TextField
                                                variant="outlined"
                                                id={"amount_" + incentive.incentiveCode}
                                                size="small"
                                                value={formatter.format(parseFloat(discountProgram.amountSix).toFixed(2))}
                                                className="qualifierTextbox"
                                                style={{ backgroundColor: "white" }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            ></TextField>
                                            :
                                            <TextField
                                            variant="outlined"
                                            id={"amount_" + incentive.incentiveCode}
                                            size="small"
                                            value={incentive?.incentiveCode !== "" &&
                                            incentive?.incentiveCode !== " " &&
                                            incentive?.incentiveCode !== undefined ?
                                            formatter.format(getProgramValue(incentive.progVal)) : formatter.format(0)}
                                            className="qualifierTextbox"
                                            style={{ backgroundColor: "white" }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        ></TextField>
                                        }                              
                                    </td>
                            </tr>
                        );
                    }
                })}
                <tr className="total-row">
                    <td className="last-row" colSpan="6">
                        Total Incentive Amount Received
                    </td>
                    <td className="total-column">
                        <div className="total-received">
                            <TextField
                                variant="standard"
                                id={"total_amount"}
                                size="small"
                                value={formatter.format(totalValue)}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                    style: {fontSize: 16, fontWeight: "bold"},
                                }}
                            ></TextField>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="group-bottom"></div>
            <Divider/>
        </div>
    );

};

export default CustomerIncentiveProg;